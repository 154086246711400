import React, { useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    makeStyles,
    Theme,
    createStyles,
    Popover,
    Button
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { File } from '../../blocks/documentation/src/DocumentationFolderController';
import FilePreview from './FilePreview.web';
import DeleteConfirmationPopup from './DeleteConfirmationPopup.web';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        folder: {
            padding: theme.spacing(2),
            textAlign: 'center',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        fileIcon: {
            width: '100%',
            maxWidth: '100px',
            height: 'auto',
            margin: '6px auto',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '70px',
            }
        },
        fileName: {
            wordBreak: 'break-word',
            cursor: 'pointer',
            fontFamily: "DIN Next LT Arabic Regular",
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
        },
        fileSize: {
            fontFamily: "DIN Next LT Arabic Regular",
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
        },
        popover: {
            padding: 0,
            width: '200px',
            maxWidth: '90vw',
            [theme.breakpoints.down('xs')]: {
              width: '180px',
            },
          },
        popoverButton: {
            fontFamily: "DIN Next LT Arabic Regular",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            justifyContent: 'flex-start',
            width: '100%',
            textTransform: 'none',
            transition: 'background-color 0.3s, color 0.3s',
            padding: '10px 16px',
            '&:hover': {
                backgroundColor: '#1B4FE4',
                borderRadius: 'inherit',
                color: theme.palette.primary.contrastText,
                '& .MuiSvgIcon-root': {
                    color: theme.palette.primary.contrastText,
                },
            },
        },
    })
);

const DownloadedDocs = ({
    downloadedFile,
    onViewFile,
    onDeleteFile,
    onEditFile,
    renderFileIcon
}: {
    downloadedFile: File;
    onViewFile: (id: string) => void;
    onDeleteFile: (id: string) => void;
    onEditFile: (id: string, fileName: string) => void;
    renderFileIcon: (type: string) => string;
}) => {
    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [deletePopupOpen, setDeletePopupOpen] = useState(false);

    const handleItemClick = () => {
        setSelectedFile(downloadedFile);
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleRename = () => {
        onEditFile(downloadedFile.id, downloadedFile.name);
        handlePopoverClose();
    };

    const handleDeleteClick = () => {
        setDeletePopupOpen(true);
        handlePopoverClose();
    };

    const handleDeleteConfirm = () => {
        onDeleteFile(downloadedFile.id);
        setDeletePopupOpen(false);
    };

    const handleDeleteCancel = () => {
        setDeletePopupOpen(false);
    };

    const fileIcon: string = renderFileIcon(downloadedFile.type || '');

    const open = Boolean(anchorEl);

    return (
        <Grid item xs={6} sm={4} md={3}>
            <Box className={classes.folder}>
                <div>
                    <img
                        src={fileIcon}
                        onClick={handleItemClick}
                        className={classes.fileIcon}
                        style={{ cursor: 'pointer' }}
                        alt={downloadedFile.name}
                    />
                    <Typography 
                        variant="subtitle2" 
                        className={classes.fileName}
                        onClick={handlePopoverOpen}
                    >
                        {downloadedFile.name}
                    </Typography>
                </div>
            </Box>
            {/* <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                classes={{
                    paper: classes.popover,
                  }}
                  marginThreshold={16}
            >
                <Box className={classes.popover}>
                    <Button
                        startIcon={<EditIcon />}
                        onClick={handleRename}
                        className={classes.popoverButton}
                    >
                        Rename
                    </Button>
                    <Button
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteClick}
                        className={classes.popoverButton}
                    >
                        Delete
                    </Button>
                </Box>
            </Popover> */}
            {selectedFile && <FilePreview isOpen={isOpen} onClose={handleClose} file={selectedFile} />}
            {/* <DeleteConfirmationPopup
                open={deletePopupOpen}
                onClose={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
                itemName={downloadedFile.name}
                itemId={downloadedFile.id}
            /> */}
        </Grid>
    );
};

export default DownloadedDocs;
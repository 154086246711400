// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { Alert } from "react-native";
import DocumentPicker from 'react-native-document-picker';
import { getStorageData } from "../../../framework/src/Utilities";
import { customDocIcon, customFolderIcon, customPdfIcon, customSheetIcon } from "./assets";
import ApiRequest from "../../../components/src/ApiRequest.web";
import { toast } from "react-toastify";
import { getToken, removeToken } from "../../../components/src/utils.web";
export interface FolderFiles {
  url: string,
  blob_id: number,
  filename: string,
  folder_name?: string
}
interface FolderItems {
  folder_name: string,
  folder_medias: Array<FolderFiles>,
  gallery: { id: number }
}

interface FolderMedia {
  data: {
    id: number,
    attributes: FolderItems
  }
}

interface MenuModalData {
  blobId: number,
  fileAndFolderName: string
  renameModal?: string
}

interface ApiCallData {
  contentType?: string,
  method: string,
  endPoint: string,
  body?: Object,
  type?: string
}

interface SuccessResponse {
  message: string;
}
interface ErrorResponse {
  errors: string;
}

interface TokenExpire {
  errors: Array<TokenAlert>
}
interface TokenAlert {
  token: string
}

type FileMenuType = MenuModalData | boolean | string;

export interface File {
  id: string;
  url?: string;
  name: string;
  type: string;
}


interface Folder {
  id: string;
  name: string;
  files: File[];
  items: number;
  size: string;
}

export interface IFileOrFolder {
  parentFolderId?: string;
  id: string;
  url?: string;
  name: string;
  file?: File;
  items: number;
  type: string;
}

// For Mobile

export interface Filefolder {
  blob_id: number;
  filename: string;
  url: string;
}

interface GetDocumentFolder {
  message: string;
  data: Array<Filefolder>;
}

interface Getmediafile {
  data: Dataobject;
}

export interface Dataobject {
  type: string;
  attributes: Attributeobjectformedia
}

export interface Attributeobjectformedia {
  folder_medias: Filefolder[];
  folder_name: string;
}

interface Errorobject {
  errors: string;
}


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  i18n?: any;
}

export interface IPaginationData {
  currentPage: number;
  nextPage: number;
  prevPage: number;
  totalPages: number;
  currentCount: number;
  totalCount: number;
}

interface S {
  loader: boolean;
  fileMenu: FileMenuType;
  renameModal: string;
  deleteModal: boolean;
  uploadedFiles: [];
  renameValue: string;
  menuOption: string;
  folderMenuOptions: boolean;
  folderMedia: FolderFiles[];
  folderId: number;
  galleryId: number;
  blobId: number;
  folderTitle: string;
  folders: {
    [key: string]: IFileOrFolder;
  };
  mainFolderLength: string;
  isNewFolderDialogOpen: boolean;
  currentParentFolder: string;
  navigationHistory: string[];
  editingFolderId: string;
  folderName: string;
  fileOrFolderType: 'Folder' | 'File';
  deletePopup: boolean;
  accountId: string;
  parentGalleryId: string;
  currentFolderId: string;
  currentFolderContents: IFileOrFolder[];
  recentDownloads: File[];
  editingFileName: boolean;
  paginationData:IPaginationData;

  // For Mobile

  modalDotsFolder: boolean;
  documentListFolder: Array<Filefolder>;
  ShowData: string;
  folderNameDoc: string;
  fileRenameTextDoc: string;
  folderRename: boolean;
  modalFileRename: boolean;
  cancelDoc: boolean;
  renameDoc: boolean;
  middleModal: boolean;
  modalDelete: boolean;
  fileDeleteTextDoc: string;
  deleteDoc: boolean;
  blobIdDoc: number;
  floderWord: string;
  isloadingDoc: boolean;
  documentIdDoc?: number;
  isResponseOne: object;
  yesNoModalDoc: boolean;
  yesNoModalFileDoc: boolean;
  responseFailureData: boolean;

}

interface SS {
  id: string;
}

export default class DocumentationFolderController extends BlockComponent<
  Props,
  S,
  SS
> {
  renameFileApiCallId: string = "";
  renameFolderApiCallId: string = "";
  folderMediaApiCallId: string = "";
  deleteFileApiCallId: string = "";
  deleteFolderApiCallId: string = "";
  uploadFilesApiCallId: string = "";

  apiCallIdGetAcountId: string = "";
  apiCallIdGetGalleryId: string = "";
  apiCallIdCreateFolder: string = "";
  apiCallIdGetAllFolder: string = "";
  apiCallIdGetRecentDownloads: string = "";
  apiCallIdGetSingleFolder: string = "";
  apiCallIdUpdateFolderName: string = "";
  apiCallIdAddDocument: string = "";
  apiCallIdUpdateFileName: string = "";
  apiCallIdGetAllFile: string = "";
  apiCallIdDeleteFile: string = "";
  apiCallIdDeleteFolder: string = "";
  apiCallIdDownloadDocument: string = "";




  // For Mobile

  getAddFiletoFolderApiCell: string = "";
  getFolderMedia: string = "";
  getDeleteFolderApiCallId: string = "";
  getRenameFolderApiCallId: string = "";
  getDeleteFileApiCallId: string = "";
  getPatchRenameFileApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      loader: false,
      fileMenu: false,
      deleteModal: false,
      renameModal: "",
      folderMenuOptions: false,
      uploadedFiles: [],
      renameValue: "",
      menuOption: "",
      folderMedia: [],
      folderId: 0,
      galleryId: 0,
      blobId: 0,
      folderTitle: "",

      folders: {},
      mainFolderLength: '0',
      isNewFolderDialogOpen: false,
      currentParentFolder: "",
      navigationHistory: [],
      editingFolderId: '',
      folderName: '',
      fileOrFolderType: this.handleTranslationChange('folder'),
      deletePopup: false,
      accountId: '',
      parentGalleryId: '',
      currentFolderId: '',
      currentFolderContents: [],
      recentDownloads: [],
      editingFileName: false,
      // For Mobile

      modalDotsFolder: false,
      documentListFolder: [],
      ShowData: '',
      folderNameDoc: "",
      fileRenameTextDoc: '',
      folderRename: false,
      modalFileRename: false,
      modalDelete: false,
      cancelDoc: false,
      renameDoc: false,
      middleModal: false,
      fileDeleteTextDoc: '',
      deleteDoc: false,
      blobIdDoc: 0,
      floderWord: "",
      isloadingDoc: false,
      documentIdDoc: 0,
      isResponseOne: {},
      yesNoModalDoc: false,
      yesNoModalFileDoc: false,
      responseFailureData: false,
      paginationData: {
        currentPage: 1,
        nextPage: 0,
        prevPage: 0,
        totalPages: 0,
        currentCount: 0,
        totalCount: 0
    }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      // For Mobile

      if (responseJson?.status === 500) {
        this.showAlert("Error", "Internal Server Error");
        return;
      }

      if (responseJson && !responseJson.errors) {
        this.getAllSuccessFunction(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.getAllErrorFunction(apiRequestCallId, responseJson);
      }

      // For Web
      // custom API call
      const apiRequestCallIds = {
        [this.apiCallIdGetAcountId]: this.getAccountIdResponse,
        [this.apiCallIdGetGalleryId]: this.getParentGalleryIdResponse,
        [this.apiCallIdCreateFolder]: this.createFolderResponse,
        [this.apiCallIdGetAllFolder]: this.getAllFolderResponse,
        [this.apiCallIdGetRecentDownloads]: this.getRecentDownloadsResponse,
        [this.apiCallIdDownloadDocument]: this.downloadDocumentResponse,
        [this.apiCallIdUpdateFolderName]: this.renameFolderNameResponse,
        [this.apiCallIdAddDocument]: this.addDocumentResponse,
        [this.apiCallIdUpdateFileName]: this.renameFileNameResponse,
        [this.apiCallIdDeleteFile]: this.destoryFileResponse,
        [this.apiCallIdDeleteFolder]: this.destroyFolderResponse,
       
  
        // Add more API call IDs and handlers as needed
      };
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
        apiRequestCallIds[apiRequestCallId](apiResponse);
      }
    }
  }

  // web events
  async componentDidMount(): Promise<void> {
    //  For Mobile
    this.setState({
      documentIdDoc: this.props.navigation.state?.params?.documentId, ShowData: this.props.navigation.state?.params?.item?.id,
    }
      , async () => {
        await this.getFolderMediaList()
      }
    )
    //  For Web
    const folderId = parseInt(this.props.navigation.getParam("folderId"))
    const galleryId = parseInt(this.props.navigation.getParam("galleryId"))
    this.getFolderData(folderId, galleryId);
    this.getAccountIdRequest();
    
  }

  handleMenuModal = (value: MenuModalData | boolean) => { this.setState({ fileMenu: value }) }

  handleFolderOptionsModal = (value: boolean) => { this.setState({ folderMenuOptions: value }) }

  handleRenameModal = (value: string, element: FileMenuType) => {
    if (typeof element === "string") {
      this.setState({
        renameModal: value,
        folderMenuOptions: false,
        renameValue: element,
      })
    }
    else if (typeof element !== "boolean") {
      this.setState({
        renameModal: value,
        folderMenuOptions: false,
        renameValue: element.fileAndFolderName
      })
    }
    else {
      this.setState({
        renameModal: value,
        folderMenuOptions: false,
      })
    }
  }

  handleDeleteModal = (value: boolean) => { this.setState({ deleteModal: value, folderMenuOptions: false }) }

  handleRename = (value: React.ChangeEvent<HTMLInputElement>) => { this.setState({ renameValue: value.target?.value }) }

  apiCall = async (data: ApiCallData) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getFolderData = async (folderId: number, gallery_id: number) => {
    this.setState({ loader: true })
    this.folderMediaApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint:`bx_block_documentation/folder_documents/${folderId}?data[attributes][gallery_id]=${gallery_id}`,
    })
  }

  handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let files = Array.from(event.target.files!)
    let formData = new FormData();
    for (const file of files) {
      formData.append("data[attributes][gallery_id][]", `${this.state.galleryId}`)
      formData.append("data[attributes][folder_medias][]", file)
    }
    this.setState({ loader: true })
    this.uploadFilesApiCallId = await this.apiCall({
      method: 'PATCH',
      endPoint:`bx_block_documentation/folder_documents/${this.state.folderId}`,
      body: formData,
      type: "formData"
    })
  }

  renameFile = async (value: FileMenuType) => {
    
    if (this.state.renameModal === "file" && typeof value !== "string" && typeof value !== "boolean") {
      let bodyData = {
        data: {
          attributes: {
            gallery_id: this.state.galleryId,
            blob_id: value.blobId,
            filename: this.state.renameValue
          }
        }
      }
      this.setState({ loader: true })
      this.renameFileApiCallId = await this.apiCall({
        contentType: "application/json",
        method: 'PATCH',
        endPoint:`/bx_block_documentation/folder_documents/${this.state.folderId}/update_file_name`,
        body: bodyData,
      })
    } else {
      let bodyData = {
        data: {
          attributes: {
            gallery_id: this.state.galleryId,
            folder_name: this.state.renameValue
          }
        }
      }
      this.renameFolderApiCallId = await this.apiCall({
        contentType: "application/json",
        method: 'PATCH',
        endPoint:`bx_block_documentation/folder_documents/${this.state.folderId}/update_folder_name`,
        body: bodyData,
      })
    }
    this.setState({
      renameModal: "",
      fileMenu: false,
      folderMenuOptions: false
    })
  }

  deleteFiles = async (value: FileMenuType) => {
    this.setState({ loader: true, folderMenuOptions: false })
    if (value && typeof value !== "string" && typeof value !== "boolean") {
      let bodyData = {
        data: {
          attributes: {
            blob_id: value.blobId,
            gallery_id: this.state.galleryId,
          }
        }
      }
      this.deleteFileApiCallId = await this.apiCall({
        contentType: "application/json",
        method: 'DELETE',
        endPoint:`bx_block_documentation/folder_documents/${this.state.folderId}/destroy_media_file`,
        body: bodyData,
      })
    }
    else {
      let bodyData = {
        data: {
          attributes: {
            gallery_id: this.state.galleryId,
          }
        }
      }
      this.deleteFolderApiCallId = await this.apiCall({
        contentType: "application/json",
        method: 'DELETE',
        endPoint:`bx_block_documentation/folder_documents/${this.state.folderId}`,
        body: bodyData,
      })
    }
    this.setState({ deleteModal: false, fileMenu: false });
  }

  successCallBack = (apiRequestCallId: string, responseJson: string & FolderMedia & SuccessResponse) => {
    if (apiRequestCallId === this.folderMediaApiCallId) {
      this.getFolderMediaSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.uploadFilesApiCallId) {
      this.uploadFilesSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFileApiCallId) {
      this.deleteFilesSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.renameFileApiCallId) {
      this.renameFileSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFolderApiCallId) {
      this.deleteFolderSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.renameFolderApiCallId) {
      this.renameFolderSuccesCallback(responseJson);
    }
  }
  failureCallBack = (apiRequestCallId: string, responseJson: string & ErrorResponse & TokenExpire) => {
    if (apiRequestCallId === this.folderMediaApiCallId) {
      this.getFolderMediaFailureCallback(responseJson);
    } else if (apiRequestCallId === this.uploadFilesApiCallId) {
      this.uploadFilesFailureCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFileApiCallId) {
      this.deleteFilesFailureCallback(responseJson);
    } else if (apiRequestCallId === this.renameFileApiCallId) {
      this.renameFileFailureCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFolderApiCallId) {
      this.deleteFolderFailureCallback(responseJson);
    } else if (apiRequestCallId === this.renameFolderApiCallId) {
      this.renameFolderFailureCallback(responseJson);
    }
  }

  getFolderMediaSuccesCallback = (response: FolderMedia) => {
    this.setState({ loader: false })
    this.setState({
      folderMedia: response.data.attributes.folder_medias,
      folderTitle: response.data.attributes.folder_name,
      galleryId: response.data.attributes.gallery.id,
      folderId: response.data.id,
    })
  }

  getFolderMediaFailureCallback = (response: TokenExpire) => {
    this.setState({ loader: false })
    if (!!this.state.folderId && !!this.state.galleryId) {
      this.getFolderData(this.state.folderId, this.state.galleryId);
    }
  }

  uploadFilesSuccesCallback = (response: string) => {
    this.setState({ loader: false, folderMenuOptions: false })
    this.showAlert('Alert', 'Files uploaded successfully')
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  uploadFilesFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false, folderMenuOptions: false })
    this.showAlert('Alert', response.errors)
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  renameFileSuccesCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  renameFileFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false, renameModal: "" })
    this.showAlert('Alert', response.errors)
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  renameFolderSuccesCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  renameFolderFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false, renameModal: "" })
    this.showAlert('Alert', response.errors)
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  deleteFilesSuccesCallback = (response: string) => {
    this.setState({ loader: false })
    this.showAlert('Alert', 'File deleted successfully')
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  deleteFilesFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  deleteFolderSuccesCallback = (response: string) => {
    this.setState({ loader: false })
    this.props.navigation.goBack();
  }

  deleteFolderFailureCallback = (response: string) => {
    this.setState({ loader: false })
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  // For Mobile

  getAllSuccessFunction = (apiRequestCallId: string, responseJson: GetDocumentFolder & Getmediafile) => {
    if (apiRequestCallId === this.getAddFiletoFolderApiCell) {
      this.getAddFiletoFolderSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getFolderMedia) {
      this.FolderMediaSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderSuccesCallBackID(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderSuccesCallBackID(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileSuccesCallBack(responseJson);
    }
  }

  getAllErrorFunction = (apiRequestCallId: string, responseJson: Errorobject & string) => {
    if (apiRequestCallId === this.getAddFiletoFolderApiCell) {
      this.getAddFiletoFolderFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getFolderMedia) {
      this.FolderMediaFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderFailureCallBackID(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderFailureCallBackID(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileFailureCallBack(responseJson);
    }
  }

  getAddFiletoFolderSuccesCallBack = async (responseJson: GetDocumentFolder) => {
    this.setState({ isloadingDoc: false, modalDotsFolder: false })
    this.getFolderMediaList()
  };

  getAddFiletoFolderFailureCallBack = async (responseJson: Errorobject) => {
    this.setState({ isloadingDoc: false, modalDotsFolder: false })
    if (responseJson?.errors) {
      Alert.alert('Alert', responseJson.errors)
    }
  };

  FolderMediaSuccesCallBack = async (responseJson: Getmediafile) => {
    if (responseJson?.data) {
      this.setState({ documentListFolder: responseJson?.data?.attributes?.folder_medias, floderWord: responseJson?.data?.attributes?.folder_name, folderNameDoc: responseJson?.data?.attributes?.folder_name })
    }
  };

  FolderMediaFailureCallBack = async (responseJson: string) => {
    Alert.alert("@@@ ==== createDocumentFailureCallBack", responseJson);
    this.setState({ isloadingDoc: false })
  };

  deleteFolderSuccesCallBackID = async (responseJson: GetDocumentFolder) => {
    this.setState({ isloadingDoc: false })
    Alert.alert(responseJson.message)
    await this.props.navigation.goBack()
  };

  deleteFolderFailureCallBackID = async (responseJson: string) => {
    Alert.alert("@@@ ==== deleteFolderFailureCallBack", responseJson);
    this.setState({ isloadingDoc: false })
  };

  renameFolderSuccesCallBackID = async (responseJson: GetDocumentFolder) => {
    this.setState({ folderRename: false, folderNameDoc: '', blobIdDoc: 0, })
    this.showAlert('Alert', responseJson.message)
    this.getFolderMediaList()
  };

  renameFolderFailureCallBackID = async (responseJson: string) => {
    Alert.alert("@@@ ==== deleteFolderFailureCallBack", responseJson);
    this.setState({ isloadingDoc: false })
  };

  deleteFileSuccesCallBack = async (responseJson: GetDocumentFolder) => {
    this.setState({ modalDelete: false, blobIdDoc: 0, fileDeleteTextDoc: '', isloadingDoc: false, yesNoModalFileDoc: false })
    this.showAlert('Alert', responseJson.message)
    this.getFolderMediaList()
  };

  deleteFileFailureCallBack = async (responseJson: string) => {
    Alert.alert("@@@ ==== deleteFileFailureCallBack", responseJson);
    this.setState({ isloadingDoc: false })
  };

  renameNewFileSuccesCallBack = async (responseJson: GetDocumentFolder) => {
    this.setState({ modalFileRename: false, blobIdDoc: 0, fileRenameTextDoc: '' })
    this.showAlert('Alert', responseJson.message)
    this.getFolderMediaList()
  };

  renameNewFileFailureCallBack = async (responseJson: Errorobject) => {
    this.setState({ modalFileRename: false, })
    if (responseJson.errors) {
      Alert.alert('Alert', responseJson.errors)
    }
  };

  AddFiletoFolderApiCell = async (isResponseUri: string, namefile: string) => {
    this.setState({ isloadingDoc: true })
    let formData = new FormData();
    formData.append("data[attributes][folder_medias][]",
      JSON.parse(JSON.stringify({
        uri: isResponseUri,
        type: "sample/pdf",
        name: namefile
      }))
    )
    formData.append("data[attributes][gallery_id][]", `${this.state.documentIdDoc}`)

    this.getAddFiletoFolderApiCell = await this.apiCall({
      contentType: "multipart/form-data",
      method: 'PATCH',
      endPoint:`bx_block_documentation/folder_documents/${this.state.ShowData}`,
      body: formData,
      type: 'formData'
    });
  }

  getFolderMediaList = async () => {
    this.getFolderMedia = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint:`bx_block_documentation/folder_documents/${this.state.ShowData}?data[attributes][gallery_id]=${this.state.documentIdDoc}`,
      type: "",
      body: '',
    });
  }

  DeleteFolderApiCell = async () => {
    this.setState({ isloadingDoc: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentIdDoc,
        }
      }
    }
    this.getDeleteFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint:`bx_block_documentation/folder_documents/${this.state.ShowData}`,
      body: apidata,
      type: ""
    });
  }

  RenameFolderApiCell = async () => {
    this.setState({ folderRename: false })
    if (this.state.folderNameDoc.trim().length == 0) {
      Alert.alert('Alert', 'Please Enter File Name');
      return
    }
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentIdDoc,
          folder_name: this.state.folderNameDoc
        }
      }
    }

    this.getRenameFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint:`bx_block_documentation/folder_documents/${this.state.ShowData}/update_folder_name`,
      body: apidata,
      type: ""
    });
  }

  DeleteFileApiCell = async () => {
    this.setState({ isloadingDoc: true })
    let apidata = {
      data: {
        attributes: {
          blob_id: this.state.blobIdDoc,
          gallery_id: this.state.documentIdDoc
        }
      }
    }
    this.getDeleteFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint:`bx_block_documentation/folder_documents/${this.state.ShowData}/destroy_media_file`,
      body: apidata,
      type: ""
    });
  }

  RenameFileApiCell = async () => {
    if (this.state.fileRenameTextDoc.trim().length == 0) {
      this.showAlert('Alert', 'Please Enter File Name');
      return
    }
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentIdDoc,
          blob_id: this.state.blobIdDoc,
          filename: this.state.fileRenameTextDoc,
        }
      }
    }
    this.getPatchRenameFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint:`/bx_block_documentation/folder_documents/${this.state.ShowData}/update_file_name`,
      body: apidata,
      type: ""
    });
  }


  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart;
  };

  deleteFolderModal = () => { this.setState({ modalDotsFolder: false, yesNoModalDoc: true }) }

  yesNoModalFalseDoc = () => this.setState({ yesNoModalDoc: false, yesNoModalFileDoc: false })

  responseDeleteYesNoFile = () => this.DeleteFileApiCell()

  goBackCell = () => this.props.navigation.goBack()

  documentFolderClose = () => this.setState({ modalDotsFolder: true }, () => this.setState({ cancelDoc: false, renameDoc: false }))

  renameFolderModal = () => this.setState({ modalDotsFolder: false, folderRename: true })

  middleModalTrue = (Itemid: number, fileName: string) => this.setState({ blobIdDoc: Itemid, fileDeleteTextDoc: fileName, fileRenameTextDoc: fileName }, () => { this.setState({ middleModal: true, cancelDoc: false, renameDoc: false, deleteDoc: false }) })

  documentFolderModalFalse = () => this.setState({ modalDotsFolder: false })

  changeTextFileModal = (text: string) => { this.setState({ fileRenameTextDoc: text }) }

  changeDeleteFileModalDoc = (text: string) => { this.setState({ fileDeleteTextDoc: text }) }

  changeTextFolderModalRename = (text: string) => { this.setState({ folderNameDoc: text }) }

  cancelForAll = () => this.setState({ folderRename: false, modalFileRename: false, cancelDoc: true, renameDoc: false, modalDelete: false })

  fileRenameState = () => { this.setState({ cancelDoc: false, renameDoc: true, modalFileRename: false }, () => this.RenameFileApiCell()) }

  deleteTouchableState = () => { this.setState({ cancelDoc: false, deleteDoc: true, modalDelete: false, yesNoModalFileDoc: true }) }

  folderRenameTouch = () => {
    this.setState({ cancelDoc: false, renameDoc: true, folderRename: false }, async () => {
      if (!this.state.folderRename) {
        await this.RenameFolderApiCell()
      }
    })
  }

  middleModalFalse = () => this.setState({ middleModal: false })

  renameFileinFolder = () => this.setState({ modalFileRename: true, middleModal: false })

  deleteFileinFolder = () => this.setState({ modalDelete: true, middleModal: false })

  uplaodDocumentFile = async () => {
    try {
      const isResponse = await DocumentPicker.pick({
        type: [DocumentPicker.types.pdf]
      })
      this.setState({ isResponseOne: isResponse }, () => {
        if (this.state.isResponseOne) {
          this.AddFiletoFolderApiCell(isResponse[0].uri, isResponse[0].name)
        }
      })
    }
    catch (error) {
    }
  };

  // API Request for document storage
  getAccountIdRequest = async () => {
    const token = getToken();

    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token
    }

    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.getProfileEndpoint,
      method: "GET",
    });

    this.apiCallIdGetAcountId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getParentGalleryIdRequest = async () => {
    const {accountId} = this.state;
    const token = getToken();

    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token
    }

    const body = {
      "account_id": accountId
    }

    const requestMessage = ApiRequest({
      header: header,
      payload: JSON.stringify(body),
      endPoint: configJSON.getGalleryEndpoint,
      method: "POST",
    });

    this.apiCallIdGetGalleryId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createFolderRequest = async (parentFolderId: string = '') => {
    const {parentGalleryId, folderName} = this.state;
    const token = getToken();

    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token
    }

    const body = {
      "data": {
        "attributes": {
          "folder_name": folderName,
          "folder_type": "document",
          "gallery_id": parentGalleryId,
          ...(parentFolderId && { parent_folder_id: parentFolderId })
        }
      }
    }

    const requestMessage = ApiRequest({
      header: header,
      payload: JSON.stringify(body),
      endPoint: configJSON.folderEndpoint,
      method: "POST",
    });

    this.apiCallIdCreateFolder = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllFolderRequest = async () => {
    this.setState({folders: {}})
    const { parentGalleryId } = this.state;
    const token = getToken();

    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token
    }

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.folderEndpoint}?gallery_id=${parentGalleryId}`,
      method: "GET",
    });

    this.apiCallIdGetAllFolder = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  downloadDocumentRequest = async (blod_id: string) => {
    const { parentGalleryId, accountId } = this.state;
    const token = getToken();

    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token
    }

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `bx_block_documentstorage/gallery/${parentGalleryId}/download_file?blob_id=${blod_id}&account_id=${accountId}`,
      method: "GET",
    });

    this.apiCallIdDownloadDocument = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRecentDownloadsRequest = async () => {
    this.setState({recentDownloads: []})
    const { parentGalleryId, accountId } = this.state;
    const token = getToken();

    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token
    }

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `bx_block_documentstorage/gallery/${parentGalleryId}/recent_downloads?account_id=${accountId}?per_page=12&page=${this.state.paginationData.currentPage}`,
      method: "GET",
    });

    this.apiCallIdGetRecentDownloads = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  renameFolderNameRequest = async (newName: string, id: string) => {
    const { parentGalleryId } = this.state;
    const token = getToken();

    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token
    }
    
    const body = {
      "data": {
        "attributes": {
          "folder_name": newName,
          "gallery_id": parentGalleryId
        }
      }
    }

    const requestMessage = ApiRequest({
      header: header,
      payload: JSON.stringify(body),
      endPoint: `${configJSON.folderEndpoint}/${id}/update_folder_name`,
      method: "PATCH",
    });

    this.apiCallIdUpdateFolderName = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addDocumentRequest = async (id: string, files: any[]) => {
    const { parentGalleryId } = this.state;
    const token = getToken();
    const header = {
      token
    };
  
    const formData = new FormData();
    formData.append('gallery_id', parentGalleryId);
  
    files.forEach((file) => {
      formData.append(`folder_media[]`, file);
    });
  
    const requestMessage = ApiRequest({
      header: header,
      payload: formData,
      endPoint: `${configJSON.folderEndpoint}/${id}`,
      method: "PATCH",
    });
  
    this.apiCallIdAddDocument = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  renameFileNameRequest = async (updatedName: string, blobId: string) => {
    const { parentGalleryId, accountId } = this.state;
    const token = getToken();

    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token
    }

    const body ={
      "data": {
        "attributes": {
          "blob_id": blobId,
          "filename": updatedName
        }
      }
    }

    const requestMessage = ApiRequest({
      header: header,
      payload: JSON.stringify(body),
      endPoint: `bx_block_documentstorage/gallery/${parentGalleryId}/update_file_name?account_id=${accountId}`,
      method: "PATCH",
    });

    this.apiCallIdUpdateFileName = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  destroyFileRequest = async (blobId: string) => {
    const { parentGalleryId } = this.state;
    const parentId = this.getParentId();
    const token = getToken();

    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token
    }

    const body ={
      "data": {
        "attributes": {
          "blob_id": blobId,
        }
      }
    }

    const requestMessage = ApiRequest({
      header: header,
      payload: JSON.stringify(body),
      endPoint: `${configJSON.folderEndpoint}/${parentId}/destroy_media_file?gallery_id=${parentGalleryId}`,
      method: "DELETE",
    });

    this.apiCallIdDeleteFile = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  destroyFolderRequest = async (folderId: string) => {
    const { parentGalleryId } = this.state;
    const token = getToken();

    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token
    }

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.folderEndpoint}/${folderId}?gallery_id=${parentGalleryId}`,
      method: "DELETE",
    });

    this.apiCallIdDeleteFolder = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

   // API Response for document storage
   getAccountIdResponse = (responseJson: any) => {
    if(responseJson && responseJson.data && responseJson.data.id) {
      const accountId = responseJson.data.id;

      this.setState({accountId}, this.getParentGalleryIdRequest)
    } else if (
      responseJson.errors &&
      responseJson.errors.length > 0 &&
      responseJson.errors[0].token
    ) {
      const goTologin = new Message(getName(MessageEnum.NavigationMessage));
      goTologin.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
      goTologin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(goTologin);
      removeToken();
      toast.error(responseJson.errors[0].token)
    } else {
      toast.error('Account id not Found. Please login again.')
    }
   }

   getParentGalleryIdResponse = (responseJson: any) => {
    const gallery_id = responseJson?.id || responseJson?.gallery?.id
    if(responseJson && gallery_id) {

      this.setState({parentGalleryId: gallery_id}, () => {
        this.getAllFolderRequest();
        this.getRecentDownloadsRequest();
      })
    } else {
      toast.error(responseJson.message)
    }
   }

   createFolderResponse = (responseJson: any) => {
    try {
      if (responseJson && responseJson.data && responseJson.data.attributes) {
        toast.success(this.handleTranslationChange('folder_created_successfully'));
  
      } else if (responseJson.errors && responseJson.errors.length > 0 && responseJson.errors[0]) {
        toast.error(responseJson.errors[0]);
  
      } else {
        toast.error(this.handleTranslationChange('sorry_somethingWent_wrong'));
      }
  
    } catch (error) {
      toast.error(this.handleTranslationChange('errorOccurred'));
    }
  
    this.getAllFolderRequest();
  };
  

   
   getFileTypeFromUrl = (url: string): string => {
    const fileName = url.split('/').pop();
    
    const extension = fileName?.split('.').pop()?.split('?')[0]?.toLowerCase();
    
    return extension || 'file';
  };
  

getAllFolderResponse = (responseJson: any) => {
  if (responseJson && responseJson.data && Array.isArray(responseJson.data) && responseJson.data.length > 0) {
    const newItems: Record<string, IFileOrFolder> = {};
    let count: number = 0;
    
    
    responseJson.data.forEach((item: any) => {
      const attributes = item.attributes;
      const id = item.id;
      
      const newFolder: IFileOrFolder = {
        id,
        name: attributes.folder_name,
        type: "folder",
        items: attributes.folder_medias.length,
        parentFolderId: attributes.parent_folder_id || undefined,
      };
      
      newItems[id] = newFolder;
    });

    
    responseJson.data.forEach((item: any) => {
      const attributes = item.attributes;
      const id = item.id;
      
      
      attributes.folder_medias.forEach((media: any) => {
        const fileId = `${media.blob_id}`;
        const newFile: IFileOrFolder = {
          id: fileId,
          name: media.filename,
          type: this.getFileTypeFromUrl(media.url),
          items: 0,
          url: media.url,
          parentFolderId: id
        };
        
        newItems[fileId] = newFile;
      });

      
      if (attributes.parent_folder_id && newItems[attributes.parent_folder_id]) {
        newItems[attributes.parent_folder_id].items += 1;
      }

      count = Object.values(newItems).filter(item => item.parentFolderId === undefined).length;
    });

    this.setState((prev) => ({
      mainFolderLength: count.toString(),
      folders: {
        ...prev.folders,
        ...newItems,
      },
    }));
  } else if(responseJson?.data?.length === 0) {
    toast.info('No folder records found.');
  } else {
    toast.error('Sorry! Something went wrong.');
  }
}

downloadDocumentResponse = (responseJson: any) => {
  const success = responseJson?.url;
  if (success) {
    toast.success('Document downloaded successfully')
  } else if(responseJson?.errors) {
    toast.error(responseJson.errors[0]);
  } else {
    toast.error('Sorry! Something went wrong.');
  }
  this.getRecentDownloadsRequest();
}

getRecentDownloadsResponse = (responseJson: any) => {
  const paginationResp = responseJson?.pagination
  if (responseJson && responseJson.recent_downloads && Array.isArray(responseJson.recent_downloads) && responseJson.recent_downloads.length > 0) {
    const newFiles: File[] = [];

    responseJson.recent_downloads.forEach((item: any) => {
      const newFile: File = {
        id: item.blob_id,
        url: item.url,
        name: item.filename,
        type: this.getFileTypeFromUrl(item.file_type),
      };

      newFiles.push(newFile);
    });
    const pagination: IPaginationData = {
      currentPage: paginationResp?.current_page || 1,
      nextPage: paginationResp?.next_page || null,
      prevPage: paginationResp?.prev_page || null,
      totalPages: paginationResp?.total_pages || 1,
      currentCount: paginationResp?.current_count,
      totalCount: paginationResp?.total_count,
    };

    this.setState({ recentDownloads: newFiles, paginationData: pagination });
  } else if(responseJson.message) {
    toast.error(responseJson.message);
  } else {
    toast.error('Sorry! Something went wrong.');
  }
}



renameFolderNameResponse = (responseJson: any) => {
  try {
    const message = responseJson.message || 'Unknown error occurred';

    if (message === "Folder name updated successfully") {
      toast.success(message);
    } else if (responseJson.errors && responseJson.errors.length > 0 && responseJson.errors[0]) {
      toast.error(responseJson.errors[0]);
    } else {
      toast.error(message);
    }

  } catch (error) {
    toast.error('An unexpected error occurred.');
  }

  this.getAllFolderRequest();
};


  addDocumentResponse = (responseJson: any) => {
    const success = responseJson?.data;
    if (success) {
      toast.success('Document added successfully')
    } else if(responseJson?.errors) {
      toast.error(responseJson.errors[0]);
    } else {
      toast.error('Sorry! Something went wrong.');
    }
    this.getAllFolderRequest();
  }

  renameFileNameResponse = (responseJson: any) => {
    const message = responseJson.message;
    if (message === "File name updated") {
      toast.success(message)
    } else if(responseJson.errors) {
      toast.error(responseJson.errors);
    } else {
      toast.error(message)
    }
    this.getAllFolderRequest();
    this.getRecentDownloadsRequest();
  }

  destoryFileResponse = (responseJson: any) => {
    const message = responseJson.message;
    if (message === "Successfully deleted the file") {
      toast.success(message)
    } else if(responseJson.errors){
      toast.error(responseJson.errors[0]);
    } else {
      toast.error(message);
    }
    this.setState({folders: {}})
    this.getAllFolderRequest();
    this.getRecentDownloadsRequest()
  }

  destroyFolderResponse = (responseJson: any) => {
    const message = responseJson?.message;
    if (message.includes("deleted")) {
      toast.success(message)
    } else {
      toast.error(message);
    }
    this.getAllFolderRequest();
    this.getRecentDownloadsRequest();
  }

  // Fucntions for document storage
  handleCreateNewFolder = () => {
    this.setState({ isNewFolderDialogOpen: true, editingFolderId: '' });
  };

  handleTranslationChange = (keyName: string) => {
    return this.props.i18n?.t(keyName)
  }

  handleCloseDialog = () => {
    this.setState({ isNewFolderDialogOpen: false, folderName: '' });
  };

  handlePaginatioChange = (event: any, next: number) => {
    this.setState((prevState) => ({
      paginationData: {
        ...prevState.paginationData,
        currentPage: next
      },
      recentDownloads: [],
    }), () => this.getRecentDownloadsRequest())
  }


  updateFolderName = (folderId: string) => {
    const currentDocument = this.state.folders[folderId];
    if (!currentDocument) {
      toast.error(`Document with folder_id ${folderId} not found`);
      return;
    }
    const currentFolderName = currentDocument?.name || '';
    const type = currentDocument.type !== 'folder' ? 'File' : 'Folder';

    this.setState({
      isNewFolderDialogOpen: true,
      folderName: currentFolderName,
      fileOrFolderType: type,
      editingFolderId: folderId,
    });
  };

  renameDownloadedFile = (blob_id: string) => {
    const currentDocument = this.state.recentDownloads.find(item => item.id === blob_id);
  
    if (!currentDocument) {
      toast.error(`Document with blob_id ${blob_id} not found`);
      return;
    }
  
    const currentFolderName = currentDocument.name || '';
  
    const type = currentDocument?.type !== 'folder' ? 'File' : 'Folder';
  
    this.setState({
      isNewFolderDialogOpen: true,
      folderName: currentFolderName,
      fileOrFolderType: type,
      editingFolderId: blob_id,
    });
  };
  

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parentId = this.getParentId();
    const files = event.target.files;
  
    if (files && files.length > 0 && parentId) {
      
      const fileArray = Array.from(files);
  
     
      this.addDocumentRequest(parentId, fileArray);
  
      toast.info(`Uploading ${files.length} file(s)...`);
    } else {
      toast.error('No files selected or invalid parent folder.');
    }
  
    event.target.value = '';
  };


  deleteFolder = async (folderId: string) => {
    const { folders } = this.state;

    const draftFolder = { ...folders };

    const currentFolder = folders[folderId];
    let itemsToRemove = 1;

    this.destroyFolderRequest(folderId)

    this.setState(
      {
        folders: draftFolder,
      }, () => {
        if (currentFolder.parentFolderId) {
          this.updateParentFolder(currentFolder.parentFolderId, -itemsToRemove);
        }
      });
  };
  deleteFile = (fileId: string) => {
    const { folders } = this.state;
    const draftFolder = { ...folders };
    delete draftFolder[fileId];

    
    this.destroyFileRequest(fileId)
  };
  updateParentFolder = (parentId: string, itemsDelta: number) => {
    
    this.setState(prevState => {
      const parent = prevState.folders[parentId];
      if (parent) {
        const updatedParent = {
          ...parent,
          items: Math.max(0, parent.items + itemsDelta),
        };
        return {
          ...prevState,
          folders: {
            ...prevState.folders,
            [parentId]: updatedParent
          }
        };
      }
      return prevState;
    });
  };
  onViewFolder = (folderId: string) => {
    this.setState((prev) => {
      return {
        currentParentFolder: folderId,
        navigationHistory: [...prev.navigationHistory, folderId],
      };
    });
  };

  onBack = () => {
    const { navigationHistory } = this.state;

    if (navigationHistory.length <= 1) {
      this.setState({
        currentParentFolder: "",
        navigationHistory: [],
      });
    } else {
      const newNavigationHistory = navigationHistory.slice(0, -1);
      const newCurrentFolder = newNavigationHistory[newNavigationHistory.length - 1];

      this.setState({
        currentParentFolder: newCurrentFolder,
        navigationHistory: newNavigationHistory,
      });
    }
  };
  onCreateFolder = () => {
    const { folderName, editingFolderId, folders } = this.state;
  
    if (!folderName.trim()) {
      return;
    }

    if (editingFolderId && folders[editingFolderId]) {
      const isFolder = folders[editingFolderId].type === 'folder';
      if (isFolder) {
        this.renameFolderNameRequest(folderName, editingFolderId);
      } else {
        this.renameFileNameRequest(folderName, editingFolderId);
      }
    } else {
      const parentId = this.getParentId();
      if (!parentId) {
        this.createFolderRequest(); 
      } else {
        this.createFolderRequest(parentId); 
      }
    }
  
    this.handleCloseDialog();
  };


  changeFolderName = (e: any) => {
    const newName = e.target.value;

      this.setState({
        folderName: newName,
      });
  };

  getParentId = (): string => {
    return this.state.currentParentFolder;
  };

  renderFileIcon = (type: string) => {

    const lowerType = type.toLowerCase();

    switch (lowerType) {
      case 'pdf':
        return customPdfIcon;
      case 'doc':
      case 'docx':
      case 'msword':
        return customDocIcon;
      case 'xls':
      case 'xlsx':
      case 'csv':
      case 'ms-excel':
        return customSheetIcon;
      case 'jpg':
      case 'jpeg':
      case 'png':
        return customPdfIcon;
      case 'folder':
        return customFolderIcon;
      default:
        return customFolderIcon;
    }
  };

  
}

// Customizable Area End
